.bandeau-agence {
  max-width: 100%;
  margin: auto;
  margin-bottom: 10px;
}

.read-more-hidden {
  display: none;
}

.custom-select {
  -webkit-appearance: none;
}

.skyline {
  background: url('../../images/skyline.svg');
  background-repeat: no-repeat;
  background-position: 60% bottom;
  background-size: 79%;
  height: 189px;
  position: absolute;
  top: 0px;
  right: 100px;
  margin-top: 50px;
  fill: white;
}

.account {
  color: white;
  margin-left: 1rem;
  white-space: nowrap;

  span {
    margin-right: 1rem;
  }

}

.scrollable-menu {
  height: auto;
  max-height: 70vh;
  overflow-x: hidden;
  width: 300px !important;

  @include media-breakpoint-up(lg) {
    width: 400px !important;
  }

}

.accountPhoto {
  width: 80px;
  max-height: 64px;
  border-radius: 5px;
  margin-left: -4px;
}

.immeuble-logo {
  font-size: 70pt;
}

.immeuble-logo-menu {
  font-size: 25pt;
}